import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerSlide from "./BannerSlide";
import { _t } from "../../Common/components/InjectIntlContext";
import { onGetStarted } from "../constants";

export type mainBannerSliderProps = {};
const bannerSlides = (_t: any) => [
  {
    title: _t("slide_1_title"),
    subtitle: _t("slide_1_text"),
    btnLabel: _t("get_started"),
    onClick: () => onGetStarted(),
  },
  // {
  //   title: _t("slide_2_title"),
  //   subtitle: _t("slide_2_text"),
  //   btnLabel: _t("get_started"),
  //   onClick: () => onGetStarted(),
  // },
];

const slickSettings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 8000,
  fade: true,
};

const MainBannerSlider: React.FC<mainBannerSliderProps> = () => {
  const slide = (slide, idx) => <BannerSlide info={slide} key={idx + "bs"} />;
  return (
    <div className="main-banner">
      <div className="container">
        <Slider {...slickSettings}>{bannerSlides(_t).map(slide)}</Slider>
      </div>
    </div>
  );
};

export default MainBannerSlider;
