import React from "react";
import { Typography } from "antd";
import LandingTemplate from "../../../Layouts/LandingTemplate";
import { _t } from "../../../Common/components/InjectIntlContext";
import { Link, useHistory } from "react-router-dom";
import CustomButton from "../../../Common/components/CustomButton";
const CommissionPage = ({ title, icon, items }) => {
  const translatedPoints = items(_t);
  const listItem = (item, idx) => <li key={idx}>{item}</li>;
  return (
    <LandingTemplate wrapClass="main-wrap--inner">
      <div className="custom-section custom-section--no-indent">
        <div className="container">
          <h2
            className="custom-section__title"
            style={{ marginBottom: "15px" }}
          >
            {title}
          </h2>
          <div className="commission-card">
            <ul className="commission-card__list">
              {translatedPoints.map(listItem)}
            </ul>
            <Link to="/" className="btn">
              {_t("Back")}
            </Link>
          </div>
        </div>
      </div>
    </LandingTemplate>
  );
};

export default CommissionPage;
