import React from "react";
import classNames from "classnames";
import CustomButton from "../../Common/components/CustomButton";
import { _t } from "../../Common/components/InjectIntlContext";

export type bannerSlideProps = {
  info: {
    className?: string;
    backgroundImg?: string;
    title?: string;
    subtitle?: string;
    btnLabel?: string;
    linkTo?: string;
    onClick?: () => any;
  };
};

const BannerSlide: React.FC<bannerSlideProps> = ({ info }) => {
  const {
    className,
    backgroundImg,
    title,
    subtitle,
    btnLabel,
    linkTo,
    onClick,
  } = info;

  return (
    <div className={classNames(className, "banner-slide")}>
      <div className="banner-slide__content">
        <h2 className="banner-slide__title">{title}</h2>
        <h5 className="banner-slide__sub-title">{subtitle}</h5>
        <CustomButton
          className={"btn--second banner-slide__btn"}
          btnLabel={_t("get_started")}
          linkTo={linkTo}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default BannerSlide;
