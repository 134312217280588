import React from "react";
import { Button, Space } from "antd";
import { CaretDownOutlined, EditOutlined } from "@ant-design/icons";
import { Link, generatePath } from "react-router-dom";
import { APP_ROUTES } from "../../../../../system/constants/constantsUrl";
import { _t } from "../../../../../modules/Common/components/InjectIntlContext";
const AffiliateProgramControls = ({
  rowKey,
  status,
  handleExpandOpen,
  title,
}) => {
  const programEditPage = generatePath(
    APP_ROUTES.affiliates_programs.program_edit,
    {
      project_name: title,
      program_id: rowKey,
    }
  );
  return (
    <Space>
      {status !== 1 && (
        <Button
          icon={<CaretDownOutlined />}
          shape="round"
          onClick={() => handleExpandOpen("users", rowKey)}
        >
          {_t("Users")}
        </Button>
      )}
      <Button
        icon={<CaretDownOutlined />}
        shape="round"
        onClick={() => handleExpandOpen("options", rowKey)}
      >
        {_t("details")}
      </Button>
      <Link
        to={programEditPage}
        className="ant-btn ant-btn-round"
      >
        <EditOutlined style={{ marginRight: "10px" }} />
        {_t("edit")}
      </Link>
    </Space>
  );
};

export default AffiliateProgramControls;
