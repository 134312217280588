import React, { useState } from "react";
import MainLayout from "../../../../../../modules/Layouts/MainLayouts";
import { PageTemplate } from "../../../../../../modules/Layouts/PageTemplate";
import { _t } from "../../../../../../modules/Common/components/InjectIntlContext";
import {
  useGetTranslationExportExcel,
  useGetTranslationList,
} from "../../hooks";
import {Button, Pagination, Spin, Table} from "antd";
import { PlusOutlined} from "@ant-design/icons";
import {Link, useHistory, generatePath} from "react-router-dom";
import { APP_ROUTES } from "../../../../../../system/constants/constantsUrl";
import TranslationItem from "../../components/TranslationItem";
import { FiltersWidget } from "../../components/FiltersWidget";
import { usePostMergeTranslations } from "../../hooks/usePostMergeTranslation";
import { ExcelButton } from "../../../../../../modules/Dashboard/components/Filters/ExcelButton";
import {NoData} from "../../../../../../modules/Common/components/NoData";

const TranslationList = () => {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    search: "",
    empty: false,
  });
  const handleSubmit = (values) => {
    setFilters(values);
  };
  const [
    mergeTranslation,
    { isLoading: isMerging },
  ] = usePostMergeTranslations();

  const [
    getFile,
    { isLoading: isFileLoading },
  ] = useGetTranslationExportExcel();

  const { data, isLoading } = useGetTranslationList({ page, ...filters });
  const history = useHistory();
  return (
    <MainLayout>
      <PageTemplate
        title={_t("translations")}
        actions={
          <>
            <ExcelButton clickHandler={getFile} isLoading={isFileLoading} />
            <Button
              size="large"
              shape="round"
              type="primary"
              icon={<PlusOutlined />}
              style={{ marginLeft: "10px" }}
              onClick={() =>
                history.push(APP_ROUTES.content.translations.import_excel)
              }
            >
              {_t("import_translations")}
            </Button>
          </>
        }
      >
        <Button
          size="large"
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginBottom: "20px" }}
          onClick={() =>
            history.push(APP_ROUTES.content.translations.create_key)
          }
        >
          {_t("create_new_translation")}
        </Button>
        <FiltersWidget onSubmit={handleSubmit} initialValues={filters} />
        <Spin spinning={isLoading}>
          {data && (
            <>
              {data.translations?.data && <Table
                  loading={isLoading}
                  columns={[
                    {
                      render: (v, r: any) =>        <Link to={generatePath(
                        APP_ROUTES.content.translations.translation,
                        {
                          id: r.key_id,
                        }
                      )} className="btn btn--second">
                        {_t("edit")}
                      </Link>
                    },
                    {
                      dataIndex: 'key',
                      title: _t('translation_card_key'),
                    },
                    {
                      dataIndex: 'default_message',
                      title: _t('default_message'),
                    },
                    {
                      dataIndex: 'translation',
                      title: _t('translation'),
                      render: (v: any) => <div dangerouslySetInnerHTML={{ __html: v }}></div>
                    },
                  ]}
                  pagination={false}
                  dataSource={data.translations?.data}
                  locale={{ emptyText: <NoData /> }}
              />}
              <Pagination
                current={data.translations.current_page}
                defaultCurrent={1}
                total={data.translations.total}
                onChange={setPage}
                hideOnSinglePage
                showSizeChanger={false}
                pageSize={data.translations.per_page || 20}
                className="translation-list-pagination"
              />
            </>
          )}
        </Spin>
      </PageTemplate>
    </MainLayout>
  );
};

export default TranslationList;
