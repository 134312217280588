import React from "react";
import { _t } from "../../Common/components/InjectIntlContext";
import MainLayout from "../../Layouts/MainLayouts";
import { PageTemplate } from "../../Layouts/PageTemplate";
import { Link, useParams } from "react-router-dom";
import { Col, Divider, Row, Spin } from "antd";
import { useUsersProfile } from "../apiHooks";
import { FinancialPerformanceWidget } from "../components/FinancialPerformanceWidget";
import { StatsViewWidget } from "../components/StatsViewWidget";
import { ProfileBasicInformationWidget } from "../components/ProfileBasicInformationWidget";
import { DetailsTabsWidget } from "../components/DetailsTabsWidget";
import { SpecialsWidget } from "../components/SpecialsWidget";
import Btn from "../../Common/components/Button";

const ProfileTemplate = ({ isLoading, data }) => {
  if (isLoading) {
    return <Spin spinning={isLoading}></Spin>;
  }
  return (
    <Row gutter={[30, 30]}>
      <Col md={24} xl={16}>
        <ProfileBasicInformationWidget
          user={data?.user}
          user_settings={data?.user_settings}
          cash_types={data?.cash_types}
          traffic_types={data?.traffic_types}
          ex_ussr={data?.ex_ussr}
          projects={data?.projects}
          reset_link={data?.reset_link}
          remain_password_key={data?.remain_password_key}
          managers={data?.managers}
        />
      </Col>
      <Col md={24} xl={8}>
        <FinancialPerformanceWidget user={data?.user} />
        {/*<Divider />*/}
        {/*<StatsViewWidget lastStatsView={data?.last_stats_view} />*/}
        <Divider />
        <SpecialsWidget user={data?.user} />
      </Col>
      {/*<Col span={24}>*/}
      {/*  <DetailsTabsWidget user={data?.user} />*/}
      {/*</Col>*/}
    </Row>
  );
};

export const UsersProfile = () => {
  const { partnerId } = useParams();

  const { data, isLoading } = useUsersProfile({ user_id: partnerId });

  const loginByIdAction = () => (
    <Link to={`/login-by-id?user_id=${partnerId}`}>
      <Btn type="default" title={_t("login_as")} />
    </Link>
  );

  return (
    <MainLayout>
      <PageTemplate title={_t("users_profile")} actions={loginByIdAction()}>
        <ProfileTemplate isLoading={isLoading} data={data} />
      </PageTemplate>
    </MainLayout>
  );
};
